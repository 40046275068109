<template>
  <div>
    <header id="head">
      <div class="header-bg">
        <img src="@/assets/images/6on-banner1.jpg" alt="" />
        <div class="header-content-wrap">
          <div class="header-title">
            <h2>用今天加倍的努力</h2>
            <h2>建明天成功的道路</h2>
          </div>
        </div>
      </div>
    </header>
    <!--公司简介-->
    <div class="companyinfo">
      <div class="infocontent">
        <h1>公司简介</h1>
        <div class="line"></div>
        <p class="textone">
          深圳市正品汇商务贸易有限公司依托于母公司凯华（深圳凯华技术有限公司）“一物一码”可实现对商品交易过程的全追溯。同时，正品汇精选商家以小区会员店的方式向消费者提供货真价实的商品。
        </p>
        <!-- <p class="texttwo">
          凯华云平台目前已经与建设银行，中兴通讯，加多宝等诸多厂商进行了战略性合作，公司有自己的研发专利与技术，为线下与线上众多商家提供技术支持，数据分析，用户营销推广等增值服务，公司用心做好每一件产品，服务好每一个客户。
        </p> -->
      </div>
    </div>
    <!--翻转图片-->
    <div class="content-wrap">
      <div id="content">
        <div class="content-title">
          <h2>企业文化</h2>
          <div class="cmline"></div>
          <h5>态度决定高度 细节决定成败</h5>
        </div>
        <div class="container">
          <div
            class="flip-container"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div class="flipper">
              <div class="front">
                <img src="@/assets/images/on_1_1.png" alt="" />
              </div>
              <div class="back">
                <img src="@/assets/images/on_1_2.png" alt="" />
              </div>
            </div>
          </div>
          <div class="container-title">企业价值观</div>
          <p>简单 高效 创新 务实</p>
        </div>
        <div class="container">
          <div
            class="flip-container"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div class="flipper">
              <div class="front">
                <img src="@/assets/images/on_2_1.png" alt="" />
              </div>
              <div class="back">
                <img src="@/assets/images/on_2-2.png" alt="" />
              </div>
            </div>
          </div>
          <div class="container-title">企业使命</div>
          <p>为企业提供基础数据开发平台</p>
        </div>
        <div class="container">
          <div
            class="flip-container"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div class="flipper">
              <div class="front">
                <img src="@/assets/images/on_3_1.png" alt="" />
              </div>
              <div class="back">
                <img src="@/assets/images/on_3_2.png" alt="" />
              </div>
            </div>
          </div>
          <div class="container-title">核心竞争力</div>
          <p>产品不断创新，团队勇于开拓</p>
        </div>
        <div class="container">
          <div
            class="flip-container"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div class="flipper">
              <div class="front">
                <img src="@/assets/images/on_4_1.png" alt="" />
              </div>
              <div class="back">
                <img src="@/assets/images/on_4_2.png" alt="" />
              </div>
            </div>
          </div>
          <div class="container-title">企业愿景</div>
          <p>成为中国第三家互联网金融企业</p>
        </div>
        <div class="container">
          <div
            class="flip-container"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div class="flipper">
              <div class="front">
                <img src="@/assets/images/on_5_1.png" alt="" />
              </div>
              <div class="back">
                <img src="@/assets/images/on_5_2.png" alt="" />
              </div>
            </div>
          </div>
          <div class="container-title">经验理念</div>
          <p>客户第一 诚实守信</p>
        </div>
      </div>
    </div>
    <!--地图-->
    <div class="map-wraps">
      <div class="wraps">
        <div class="infos">
          <h2>公司联系信息</h2>
          <p>联系电话 | 0755-33297563</p>
          <p>办公时间 | 星期一至五 08:30-18：00</p>
          <p>办公地址 | 深圳福田区深南大道6029号世纪豪庭大厦9楼</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "about-us",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
#head {
  height: auto;
}
.header-bg {
  position: relative;
  height: 279px;
}
.header-bg > img {
  width: 100%;
  height: 280px;
}
.header-title img {
  padding: 0 10px;
}
.header-content-wrap {
  width: 100%;
  height: 280px;
  position: absolute;
  top: 0;
  left: 0;
}
.header-title {
  position: absolute;
  top: 38%;
  left: 54%;
}
.header-title h2 {
  color: #ffffff;
  letter-spacing: 5px;
  font-size: 28px;
  height: 36px;
  line-height: 36px;
  font-weight: 600;
}
.header-title h2:last-child {
  text-indent: 24px;
}
/*div:after{*/
/*content: "";*/
/*display: block;*/
/*clear: both;*/
/*}*/
/*
*content
*/
#content {
  display: block;
}
.content-wrap {
  width: 100%;
  height: 594px;
  background: #f2f2f2;
}

.content-title {
  width: 240px;
  margin: 0px auto 86px;
  text-align: center;
}
#content {
  width: 1000px;
  margin: 0 auto;
  padding-top: 90px;
}
#content:after {
  content: "";
  display: block;
  clear: both;
}

.container {
  width: 200px;
  /*height: 500px;*/
  float: left;
  text-align: center;
}
.content-title h2 {
  color: #1e252c;
  font-size: 26px;
  margin-bottom: 10px;
}
.content-title h5 {
  color: #3b3b3b;
  font-size: 16px;
}
/*
*公司简介
*/
.companyinfo {
  width: 100%;
  background: #ffffff;
  padding-bottom: 80px;
}
.infocontent {
  width: 1000px;
  height: auto;
  margin: 0 auto;
  padding: 50px 30px 0;
}
.infocontent h1 {
  font-size: 22px;
  color: #444444;
  text-align: center;
  padding-bottom: 12px;
}
.infocontent .line {
  width: 90px;
  border-bottom: 2px solid #0382fe;
  margin: 0 auto 40px;
}
.infocontent p {
  text-indent: 34px;
  line-height: 22px;
  color: #828282;
  margin-bottom: 40px;
}
/*翻转图片*/
.flip-container {
  perspective: 1000;
}
/* flip the pane when hovered */
.flip-container:hover .flipper,
.flip-container.hover .flipper {
  transform: rotateY(180deg);
}

.flip-container,
.front,
.back {
  width: 200px;
  height: 105px;
  text-align: center;
  margin-bottom: 30px;
}

/* flip speed goes here */
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;

  position: relative;
}

/* hide back of pane during swap */
.front,
.back {
  backface-visibility: hidden;

  position: absolute;
  top: 0;
  left: 0;
}

/* front pane, placed above back */
.front {
  z-index: 2;
}

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg);
}

.container-title {
  font-size: 22px;
  color: #1f252d;
  margin-bottom: 15px;
}
.container p {
  color: #858585;
  font-size: 14px;
}
.cmline {
  width: 90px;
  border-bottom: 2px solid #0382fe;
  margin: 0 auto 20px;
}
/*地图*/
.map-wraps {
  width: 100%;
  // height: 500px;
  background: #ffffff;
}
.wraps {
  width: 1000px;
  height: 450px;
  margin: 0 auto;
  padding: 110px;
  text-align: center;
}
.wraps:after {
  content: "";
  display: block;
  clear: both;
}

#maps {
  width: 464px;
  height: 335px;
  border: 1px solid #dddddd;
  float: left;
  margin-right: 80px;
}
.infos {
  float: left;
  // margin-top: 50px;
  width: 100%;
}
.infos h2 {
  /*text-align: center;*/
  color: #1e252c;
  font-size: 26px;
  margin-bottom: 60px;
}
.infos p {
  color: #4c4c4c;
  /*height: 18px;*/
  text-align: start;
  line-height: 26px;
  font-size: 18px;
}
</style>